import {
  CLEAR_PAGE_LINK_DETAIL,
  FETCH_PAGE_DATA,
  FETCH_PAGE_DATA_ERROR,
  FETCH_PAGE_DATA_LOADING,
  GET_PAGE_LINK_DETAIL,
  GET_PAGE_LINK_DETAILS_LOADING,
  SERIES_DATA,
  SET_ACTIVE_VOLUME,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  pageLink: {
    linkLoading: false,
    linkData: null,
  },
  product: {
    productDataLoading: false,
    productData: null,
  },
  series: null,
  activeVolume: null,
};

const authReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PAGE_LINK_DETAILS_LOADING:
      return {
        ...state,
        pageLink: {
          ...state.pageLink,
          linkLoading: payload,
        },
      };
    case GET_PAGE_LINK_DETAIL:
      return {
        ...state,
        pageLink: {
          ...state.pageLink,
          linkData: payload,
        },
      };
    case CLEAR_PAGE_LINK_DETAIL:
      return {
        ...state,
        pageLink: {
          ...state.pageLink,
          linkData: null,
        },
      };
    case FETCH_PAGE_DATA_LOADING:
      return {
        ...state,
        product: {
          ...state.product,
          productDataLoading: payload,
        },
      };
    case FETCH_PAGE_DATA:
      return {
        ...state,
        product: {
          ...state.product,
          productData: payload,
        },
      };
    case FETCH_PAGE_DATA_ERROR:
      return {
        ...state,
        product: {
          ...state.product,
          productData: payload,
        },
      };
    case SERIES_DATA:
      return {
        ...state,
        series: payload,
      };
    case SET_ACTIVE_VOLUME:
      return {
        ...state,
        activeVolume: payload,
      };
    default:
      return state;
  }
});

export default authReducer;
