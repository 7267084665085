import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

var devTools;

if (process.env.NODE_ENV !== "development") {
  devTools = compose(applyMiddleware(thunk));
} else {
  devTools = applyMiddleware(thunk);
}

const store = createStore(rootReducer, devTools);

export default store;
