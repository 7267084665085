import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_GROW_BOOKS_SUCCESS,
  FETCH_DASHBOARD_KIDS_MUSIC_SUCCESS,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_VBS,
  FETCH_DASHBOARD_VBS_SUCCESS,
  GET_ALL_SIDEBAR_MENU,
  GET_ALL_SOCIAL_SIDEBAR_MENU,
  SET_LOADING,
  SET_DASHBOARD_PERMISSION,
} from "./actionTypes";

const initialState = {
  loading: false,
  dashboards: [],
  dashboard_free_VBS: [],
  dashboard_kids_music: [],
  dashboard_grow_books: [],
  sidebarMenuList: [],
  socialSidebarMenuList: [],
  dashboardPermission: [],
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    /** dashboard list */
    case FETCH_DASHBOARD:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboards: payload,
        loading: false,
      };
    /** dashboard list */
    case FETCH_DASHBOARD_VBS:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SIDEBAR_MENU:
      return {
        ...state,
        sidebarMenuList: payload,
        loading: false,
      };
    case GET_ALL_SOCIAL_SIDEBAR_MENU:
      return {
        ...state,
        socialSidebarMenuList: payload,
        loading: false,
      };
    case SET_DASHBOARD_PERMISSION:
      return {
        ...state,
        dashboardPermission: payload,
        loading: false,
      };
    case FETCH_DASHBOARD_VBS_SUCCESS:
      return {
        ...state,
        dashboard_free_VBS: payload,
        loading: false,
      };
    case FETCH_DASHBOARD_KIDS_MUSIC_SUCCESS:
      return {
        ...state,
        dashboard_kids_music: payload,
        loading: false,
      };

    case FETCH_DASHBOARD_GROW_BOOKS_SUCCESS:
      return {
        ...state,
        dashboard_grow_books: payload,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
