import {
  MAIN_CONTENT_LOADING,
  MAIN_CONTENT,
  FETCH_BLOGS_NEWS_LOADING,
  FETCH_NEWS,
  FETCH_BLOGS,
  MB_DUPLICATE_SUCCESS,
  MB_DUPLICATE_ERROR,
  MB_DUPLICATE,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  mainContentLoading: false,
  mainContent: null,
  newsBlogsLoading: false,
  news: null,
  blogs: null,
};

const homeReducer = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MAIN_CONTENT_LOADING:
      return {
        ...state,
        mainContentLoading: payload,
      };
    case MAIN_CONTENT:
      return {
        ...state,
        mainContent: payload,
      };
    case FETCH_BLOGS_NEWS_LOADING:
      return {
        ...state,
        newsBlogsLoading: payload,
      };
    case FETCH_NEWS:
      return {
        ...state,
        news: payload,
      };
    case FETCH_BLOGS:
      return {
        ...state,
        blogs: payload,
      };
       //MB DUPLICATE
    case MB_DUPLICATE:
      return {
        ...state,
        loading: true,
      };
    case MB_DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case MB_DUPLICATE_ERROR:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
});

export default homeReducer;
