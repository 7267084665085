export const SET_LOADING = "SET_LOADING";
export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_ERROR = "FETCH_DASHBOARD_ERROR";

export const FETCH_DASHBOARD_VBS = "FETCH_DASHBOARD_VBS";
export const FETCH_DASHBOARD_VBS_SUCCESS = "FETCH_DASHBOARD_VBS_SUCCESS";
export const FETCH_DASHBOARD_VBS_ERROR = "FETCH_DASHBOARD_VBS_ERROR";
export const FETCH_DASHBOARD_KIDS_MUSIC_SUCCESS =
  "FETCH_DASHBOARD_KIDS_MUSIC_SUCCESS";
export const FETCH_DASHBOARD_KIDS_MUSIC_ERROR =
  "FETCH_DASHBOARD_KIDS_MUSIC_ERROR";

export const GET_ALL_SIDEBAR_MENU = "GET_ALL_SIDEBAR_MENU";
export const GET_ALL_SOCIAL_SIDEBAR_MENU = "GET_ALL_SOCIAL_SIDEBAR_MENU";
export const FETCH_DASHBOARD_GROW_BOOKS_SUCCESS =
  "FETCH_DASHBOARD_GROW_BOOKS_SUCCESS";
export const FETCH_DASHBOARD_GROW_BOOKS_ERROR =
  "FETCH_DASHBOARD_GROW_BOOKS_ERROR";
export const SET_DASHBOARD_PERMISSION = "SET_DASHBOARD_PERMISSION";
