import {
  FETCH_USER_MEMBERSHIP_VOLUME_LOADING,
  FETCH_USER_MEMBERSHIP_VOLUME,
  IS_FREE_TRIAL,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  fetchMemberShipLoading: false,
  non_purchased_volumes: null,
  purchased_volumes: null,
  is_free_trial: false,
};

const authReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USER_MEMBERSHIP_VOLUME_LOADING:
      return {
        ...state,
        fetchMemberShipLoading: payload,
      };
    case FETCH_USER_MEMBERSHIP_VOLUME:
      return {
        ...state,
        non_purchased_volumes: payload?.non_purchased_volumes,
        purchased_volumes: payload?.purchased_volumes,
      };
    case IS_FREE_TRIAL:
      return {
        ...state,
        is_free_trial: payload,
      };
    default:
      return state;
  }
});

export default authReducer;
