import { combineReducers } from "redux";
import Auth from "./Auth/reducer";
import Layout from "./Layout/reducer";
import Home from "../modules/home/store/reducer";
import Volumes from "./Volume/reducer";
import Product from "../modules/curriculum/store/reducer";
import dashboardReducer from "./Dashboard/reducer";
const rootReducer = combineReducers({
  Auth,
  Layout,
  Home,
  Volumes,
  Product,
  dashboards: dashboardReducer,
});

export default rootReducer;
