export const GET_PAGE_LINK_DETAILS_LOADING = "GET_PAGE_LINK_DETAILS_LOADING";
export const GET_PAGE_LINK_DETAIL = "GET_PAGE_LINK_DETAIL";
export const FETCH_PAGE_DATA_LOADING = "FETCH_PAGE_DATA_LOADING";
export const FETCH_PAGE_DATA = "FETCH_PAGE_DATA";
export const FETCH_PAGE_DATA_ERROR = "FETCH_PAGE_DATA_ERROR";

export const SERIES_DATA = "SERIES_DATA";

export const SET_ACTIVE_VOLUME = "SET_ACTIVE_VOLUME";

export const CLEAR_PAGE_LINK_DETAIL = "CLEAR_PAGE_LINK_DETAIL";
